import { Component, HostListener, OnInit } from '@angular/core';
import { BudgetHomeService } from './services/budget-home.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, startWith } from 'rxjs';
import { FuseAlertType } from '@fuse/components/alert';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit
{

    // @HostListener('document:click', ['$event'])
    // onClick(event: MouseEvent): void{
    //        var link: any = document.querySelector('link[rel="icon"]');
    //        link.href = this.pathImageIsotipo;
    //        link.valueChanges.pipe(
    //         startWith(''),
    //         map(value => this.pathImageIsotipo),
    //       );
    // }


    alert: { type: FuseAlertType; message: string } = {
        type   : 'success',
        message: ''
    };
    showAlert: boolean = false;
    
    companyTradeName: string = '';
    bitCompanyDemo: boolean = false;
    folderImageIsologo: string = './../assets/images/isologo/';
    nameImageIsologo: string = '';
    pathImageIsologo: string = '';
    errorImageIsologo: boolean = false;

    pathImageIsotipo: string = './../assets/images/isotipo/';
    /**
     * Constructor
     */
    bitActive: boolean = false;

    constructor(private _budgethomeService: BudgetHomeService
        ,private router: Router
        ,private titleService: Title
        ,private route: ActivatedRoute
    )
    {
        this._budgethomeService.GetCompanyConfig().subscribe((response) => {
            this.companyTradeName = response.companyconfig.vchCompanyTradeName.toUpperCase();
            this.bitActive = response.companyconfig.objBranchGeneral.bitActive;
            this.titleService.setTitle( 'Airport transfer And Private Transportation | '+this.companyTradeName);
            this.bitCompanyDemo = response.companyconfig.bitDemo;
              try{
                  var objImgIsologo = response.companyconfig.listImages.find(c => c.bitLightTheme === true && c.bitIsologo === true);
                  this.nameImageIsologo = objImgIsologo.vchName;
                   this.pathImageIsologo = this.folderImageIsologo + this.nameImageIsologo;
                  
              }
              catch{
                  
              }
              try{
                var objImgIsotipo = response.companyconfig.listImages.find(c => c.bitIsotipo === true);
                this.pathImageIsotipo = this.pathImageIsotipo + objImgIsotipo.vchName.toString();
                // console.log('tipo',this.pathImageIsotipo);
            }
            catch{
                
            }
  
                if(this.bitActive === false)
                {
                  this.alert = 
                  {
                    type: 'error',
                    message:'At this time the system is not available. Please contact an executive.'
                  };
                  this.showAlert = true;
                }
          }, (error) => {
              console.log('error:'+error.message);
          });

          
    }
    ngOnInit(): void {
        
    }
}
