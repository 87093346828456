<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<!-- <ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation
        class="dark bg-gray-900 print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation.default"
        [opened]="false">
        <ng-container fuseVerticalNavigationContentHeader>
            <div class="flex items-center h-20 pt-6 px-8">
                <img
                    class="w-24"
                    src="assets/images/logo/logo-text-on-dark.svg"
                    alt="Logo image">
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container> -->

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <ng-container *transloco="let t">
      <div class=" relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
          <ng-container *ngIf="!isScreenSmall">
              <!-- Logo -->

              <!-- <div   class="flex items-center mx-2 lg:mr-8">
                  <div class="hidden lg:flex">
                      <img
                      *ngIf="errorImageIsologo === false"
                          class="dark:hidden w-64"
                          [src]="folderImageIsologo + nameImageIsologo" (error)="errorImageIsologo = true">
                      <img *ngIf="errorImageIsologo === false"
                          class="hidden dark:flex w-64"
                          [src]="folderImageIsologo + nameImageIsologo" (error)="errorImageIsologo = true">
                  </div>
                  <img *ngIf="errorImageIsologo === false"
                      class="flex lg:hidden w-64"
                      [src]="folderImageIsologo + nameImageIsologo" (error)="errorImageIsologo = true">
              </div> -->

              <div   class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <img
                    *ngIf="errorImageIsologo === false"
                        class="dark:hidden w-64"
                        src="./../../../../../assets/images/isologo/reservatrix-negro-y-naranja-con-fondo-Transparente.png" (error)="errorImageIsologo = true">
                    <img *ngIf="errorImageIsologo === false"
                        class="hidden dark:flex w-64"
                        src="./../../../../../assets/images/isologo/reservatrix-negro-y-naranja-con-fondo-Transparente.png" (error)="errorImageIsologo = true">
                </div>
                <img *ngIf="errorImageIsologo === false"
                    class="flex lg:hidden w-64"
                    src="./../../../../../assets/images/isologo/reservatrix-negro-y-naranja-con-fondo-Transparente.png" (error)="errorImageIsologo = true">
            </div>

            <!-- <div   class="flex items-center mx-2 lg:mr-8">
              <div class="hidden lg:flex">
                  <img
                  *ngIf="errorImageIsologo === false"
                      class="dark:hidden w-64"
                      src="./../../../../../assets/images/isologo/CocoTravelExpressAZULingles.png" (error)="errorImageIsologo = true">
                  <img *ngIf="errorImageIsologo === false"
                      class="hidden dark:flex w-64"
                      src="./../../../../../assets/images/isologo/CocoTravelExpressAZULingles.png" (error)="errorImageIsologo = true">
              </div>
              <img *ngIf="errorImageIsologo === false"
                  class="flex lg:hidden w-64"
                  src="./../../../../../assets/images/isologo/CocoTravelExpressAZULingles.png" (error)="errorImageIsologo = true">
          </div> -->


              <!-- Horizontal navigation -->
              <!-- <fuse-horizontal-navigation
                  class="mr-2"
                  [name]="'mainNavigation'"
                  [navigation]="navigation.horizontal"></fuse-horizontal-navigation> -->
          </ng-container>
          <!-- Navigation toggle button -->
          <ng-container *ngIf="isScreenSmall">
              <button
                  mat-icon-button
                  (click)="toggleNavigation('mainNavigation')">
                  <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
              </button>
          </ng-container>
          <!-- Components -->
          <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
              <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
              
              <!-- <shortcuts></shortcuts> -->
              <!-- <messages></messages>
              <notifications></notifications>
              <button
                  class="lg:hidden"
                  mat-icon-button
                  >
                  <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
              </button> -->
              <!-- <user></user> -->
              <!-- <search [appearance]="'bar'"></search> -->
              
                <!-- <a [color]="'primary'" routerLink="/public/home">
                  {{t('HeaderToolBarRootHome')}}
                </a> -->
                <button
                    mat-flat-button
                    [color]="colorhomebutton()" routerLink="/public/default">
                    {{t('HeaderToolBarRootHome')}}
                </button>
              
                <!-- <a class="" routerLink="/public/contact" >
                  {{t('HeaderToolBarRootContact')}}
                </a> -->
                <button
                    mat-flat-button
                    [color]="colorcontactbutton()" routerLink="/public/contact">
                    {{t('HeaderToolBarRootContact')}}
                </button>

                <button *ngIf="bitCompanyDemo === true" 
                mat-flat-button
                [color]="colorpricingbutton()" routerLink="/public/pricing">
                {{t('HeaderToolBarRootPricing')}}
                </button>
              
              
          <!-- <a routerLink="/auth/sign-up" >login</a> -->
              <languages></languages>

              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>person</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button  routerLink="/sign-in" [style.background]="colorsigninbutton()" [style.color]="colorsigninbuttonlabel()" mat-menu-item>
                  <mat-icon [style.color]="colorsigninbuttonlabel()" >person_outline</mat-icon>
                  <span>{{t('signin')}}</span>
                </button>
                <button routerLink="/sign-up" [style.background]="colorsignupbutton()" [style.color]="colorsignupbuttonlabel()" mat-menu-item >
                  <mat-icon [style.color]="colorsignupbuttonlabel()" >person_add</mat-icon>
                  <span>{{t('signup')}}</span>
                </button>
              </mat-menu>

              <!-- <button *ngIf="bitCompanyDemo === true" mat-icon-button [matMenuTriggerFor]="menuSettings" aria-label="Example icon-button with a menu">
                <mat-icon>settings</mat-icon>
              </button>
              <mat-menu  #menuSettings="matMenu">
                
                <button routerLink="/demo-sign-up" [style.background]="colorsignupbutton()" [style.color]="colorsignupbuttonlabel()" mat-menu-item >
                  <mat-icon [style.color]="colorsignupbuttonlabel()" >maps_home_work</mat-icon>
                  <span>Demo sign-up</span>
                </button>
              </mat-menu> -->
              
          </div>
      </div>
    </ng-container>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <jjva-ngx-whatsapp-button ngx_whatsapp_phone="'{{companyCellPhone}}'" ngx_whatsapp_text="'Whatsapp'"></jjva-ngx-whatsapp-button> -->
    <!-- <button mat-fab shareButton="facebook"
                    #fbBtn="shareButton"
                    [style.backgroundColor]="fbBtn?.color">
      <fa-icon *ngIf="fbBtn" [icon]="fbBtn.icon" size="lg"></fa-icon>
    </button> -->
    <a *ngIf="companyCellPhone" class="whatsapp" type="phone"  href='https://api.whatsapp.com/send?phone={{companyCellPhone}}' target="_blank" data-rel="external">
       <!-- <i class="fa fa-whatsapp whatsapp-icon"></i> -->
       <svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="whatsapp-icon" viewBox="0 0 16 16"> <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" fill="white"></path> </svg>
    </a>
    
    <footer class="text-center lg:text-left dark">
        
        <div class="mx-6 py-0 pt-8 text-center md:text-left">
          <div class="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <!-- <div class="">
              <h6 class="
                  uppercase
                  mb-4
                  flex
                  items-center
                  justify-center
                  md:justify-start
                ">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cubes"
                  class="w-4 mr-3" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512">
                  <path fill="currentColor"
                    d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z">
                  </path>
                </svg>
                Tailwind ELEMENTS
              </h6>
              <p>
                Here you can use rows and columns to organize your footer content. Lorem ipsum dolor
                sit amet, consectetur adipisicing elit.
              </p>
            </div> -->
            <div class="">
              <!-- <h3 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                MADE IN 
              </h3> -->
              <p *ngIf="branchAddress" class="flex items-center justify-center md:justify-start mb-4">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home"
                  class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path fill="currentColor"
                    d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z">
                  </path>
                </svg>
                <a  href="https://www.google.com.mx/maps/@20.6284684,-87.0830082,16.29z" target="_blank" >
                   {{branchAddress}}
                </a>
              </p>
              <!-- <p class="mb-4">
                <a href="#!" class="text-gray-600">React</a>
              </p> -->
            </div>
            <div class="">
              <!-- <h3 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                Email
              </h3> -->
              <p *ngIf="companyEmail" class="flex items-center justify-center md:justify-start mb-4">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope"
                  class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512">
                  <path fill="currentColor"
                    d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z">
                  </path>
                </svg>
                <a type="email"
                    href='mailto:{{companyEmail}}'>
                    <!-- <mat-icon svgIcon="email"></mat-icon> -->
                    {{companyEmail}}
                </a>
              </p>
              <!-- <p class="mb-4">
                <a href="#!" class="text-gray-600">Pricing</a>
              </p> -->
             
            </div>
            <div class="">
              <!-- <h3 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
               Phones
              </h3> -->
              <p *ngIf="companyPhone" class="flex items-center justify-center md:justify-start mb-4">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                  class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512">
                  <path fill="currentColor"
                    d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                  </path>
                </svg>
                <a type="phone"
                    href='tel:{{companyPhone}}' data-rel="external">
                    {{companyPhone}}
                </a>
              </p>
              <!-- <p class="flex items-center justify-center md:justify-start">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512">
                  <path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
                </svg>
                <a type="phone"
                    href='https://api.whatsapp.com/send?phone={{companyCellPhone}}' target="_blank" data-rel="external">
                    +52 984 806 4745 
                </a>
              </p> -->
            </div>
            <!-- <div class="">
              <p class="flex items-center justify-center md:justify-start mb-4">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512">
                  <path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
                </svg>
                <a type="phone"
                    href='https://api.whatsapp.com/send?phone={{companyCellPhone}}' target="_blank" data-rel="external">
                    +52 984 806 4745 
                </a>
              </p>
            </div> -->
          </div>
        </div>
        <div class="text-center p-4 ">
          <span>&copy; {{currentYear}} Copyright:</span>
          <a class="text-gray-600 " routerLink="/public/default"> {{companyTradeName}}</a>
        </div>
        <div class="flex justify-center items-center lg:justify-between p-4 border-b border-gray-300">
          <div class="mr-12 hidden lg:block">
            <!-- <span>Get connected with us on social networks:</span> -->
          </div>
          <div class="flex justify-center">
            <a *ngIf="branchFB" href="https://www.facebook.com/{{branchFB}}" target="_blank" class="mr-6 ">
              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f"
                class="w-2.5" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512">
                <path fill="currentColor"
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                </path>
              </svg>
            </a>
            <!-- <a href="#!" class="mr-6 text-gray-600">
              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
                class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
                </path>
              </svg>
            </a> -->
            <!-- <a href="#!" class="mr-6 text-gray-600">
              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google"
                class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
                <path fill="currentColor"
                  d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z">
                </path>
              </svg>
            </a> -->
            <a *ngIf="branchIG" href="https://www.instagram.com/{{branchIG}}/" target="_blank" class="mr-6 ">
              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram"
                class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
                </path>
              </svg>
            </a>
            <!-- <a href="#!" class="mr-6 text-gray-600">
              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in"
                class="w-3.5" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                </path>
              </svg>
            </a> -->
            <a *ngIf="branchTA" href="https://www.tripadvisor.com.mx/{{branchTA}}" target="_blank" class="mr-6">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor" d="M20 6.009h-2.829C15.211 4.675 12.813 4 10 4s-5.212.675-7.171 2.009H0c.428.42.827 1.34.993 2.04A4.954 4.954 0 0 0 0 11.008c0 2.757 2.243 5 5 5a4.97 4.97 0 0 0 3.423-1.375L10 17l1.577-2.366A4.97 4.97 0 0 0 15 16.01c2.757 0 5-2.243 5-5 0-1.112-.377-2.13-.993-2.96.166-.7.565-1.62.993-2.04zm-15 8.4c-1.875 0-3.4-1.525-3.4-3.4s1.525-3.4 3.4-3.4 3.4 1.525 3.4 3.4-1.525 3.4-3.4 3.4zm5-3.4a5.008 5.008 0 0 0-4.009-4.9C7.195 5.704 8.53 5.5 10 5.5s2.805.204 4.009.61A5.008 5.008 0 0 0 10 11.008zm5 3.4c-1.875 0-3.4-1.525-3.4-3.4s1.525-3.4 3.4-3.4 3.4 1.525 3.4 3.4-1.525 3.4-3.4 3.4zM5 8.86c-1.185 0-2.15.964-2.15 2.15s.965 2.15 2.15 2.15 2.15-.964 2.15-2.15S6.185 8.86 5 8.86zm0 2.791a.65.65 0 1 1 0-1.3.65.65 0 0 1 0 1.3zM15 8.86c-1.185 0-2.15.964-2.15 2.15s.965 2.15 2.15 2.15 2.15-.964 2.15-2.15-.965-2.15-2.15-2.15zm0 2.791a.65.65 0 1 1 0-1.3.65.65 0 0 1 0 1.3z"/>
                </svg> -->
                <svg fill="currentColor" width="25px"  viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <title>{{branchTA}}</title>
                  <path d="M23.49 14.862c0 0 0 0 0 0-1.469 0-2.659 1.191-2.659 2.659s1.191 2.659 2.659 2.659c1.469 0 2.659-1.191 2.659-2.659 0-0.49-0.132-0.948-0.363-1.342l0.007 0.013c-0.468-0.8-1.324-1.33-2.303-1.33 0 0 0 0 0 0v0zM8.505 14.862c0 0 0 0 0 0-1.469 0-2.659 1.191-2.659 2.659s1.191 2.659 2.659 2.659c1.469 0 2.659-1.191 2.659-2.659 0-0.49-0.132-0.948-0.363-1.342l0.007 0.013c-0.468-0.8-1.324-1.33-2.303-1.33v0zM23.49 12.45c0.001 0 0.001 0 0.002 0 2.8 0 5.070 2.27 5.070 5.070s-2.27 5.070-5.070 5.070c-2.8 0-5.070-2.27-5.070-5.070 0-0.933 0.252-1.807 0.691-2.558l-0.013 0.024c0.892-1.527 2.523-2.536 4.39-2.537h0zM8.505 12.447c0 0 0 0 0 0 2.801 0 5.072 2.271 5.072 5.072s-2.271 5.072-5.072 5.072c-2.801 0-5.072-2.271-5.072-5.072 0-0.934 0.252-1.809 0.692-2.56l-0.013 0.024c0.893-1.527 2.525-2.536 4.393-2.536v0zM16 8.821c2.032 0.001 3.966 0.416 5.724 1.165l-0.096-0.036c-3.173 1.090-5.447 3.97-5.628 7.405l-0.001 0.020c-0.18-3.455-2.455-6.336-5.57-7.408l-0.058-0.017c1.662-0.712 3.597-1.126 5.628-1.127h0zM16.008 6.372c-0.002 0-0.005 0-0.007 0-3.565 0-6.873 1.099-9.604 2.978l0.057-0.037h-5.45l2.453 2.668c-1.508 1.376-2.45 3.349-2.45 5.542 0 4.138 3.355 7.493 7.493 7.493 1.971 0 3.764-0.761 5.102-2.005l-0.005 0.004 2.402 2.614 2.402-2.612c1.333 1.239 3.126 2 5.097 2 4.139 0 7.494-3.355 7.494-7.494 0-2.193-0.942-4.167-2.444-5.537l-0.006-0.005 2.453-2.668h-5.436c-2.673-1.841-5.98-2.94-9.545-2.94-0.003 0-0.005 0-0.008 0h0z"></path>
                  </svg>
            </a>
            <!-- <a href="#!" class="text-gray-600">
              <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github"
                class="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                <path fill="currentColor"
                  d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z">
                </path>
              </svg>
            </a> -->
            
          </div>
          <div class="mr-12 hidden lg:block">
            <!-- <span>Get connected with us on social networks:</span> -->
          </div>
        </div>
    </footer>

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat>(click)="quickChat.toggle()" -->
