/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

// export const defaultNavigation: FuseNavigationItem[] = [
//     {
//         id   : 'example',
//         title: 'Example',
//         type : 'basic',
//         icon : 'heroicons_outline:chart-pie',
//         link : '/example'
//     }
// ];
export const defaultNavigation: FuseNavigationItem[] = [
    // {
    //     id      : 'dashboards',
    //     title   : 'Dashboards',
    //     subtitle: 'Unique dashboard designs',
    //     type    : 'group',
    //     icon    : 'heroicons_outline:home',
    //     children: [
    //         {
    //             id   : 'dashboards.project',
    //             title: 'Project',
    //             type : 'basic',
    //             icon : 'heroicons_outline:clipboard-check',
    //             link : '/dashboards/project'
    //         },
    //         {
    //             id   : 'dashboards.analytics',
    //             title: 'Analytics',
    //             type : 'basic',
    //             icon : 'heroicons_outline:chart-pie',
    //             link : '/dashboards/analytics'
    //         },
    //         {
    //             id   : 'dashboards.finance',
    //             title: 'Finance',
    //             type : 'basic',
    //             icon : 'heroicons_outline:cash',
    //             link : '/dashboards/finance'
    //         },
    //         {
    //             id   : 'dashboards.crypto',
    //             title: 'Crypto',
    //             type : 'basic',
    //             icon : 'heroicons_outline:currency-dollar',
    //             link : '/dashboards/crypto'
    //         }
    //     ]
    // },

     {
        id   : 'p.main',
        title: 'Home',
        type : 'basic',
        icon : 'heroicons_outline:home',
        link : '/p/main'
     }

    ,{
        id      : 'p.services',
        title   : 'Services',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'p.services.bookingsdetails',
                title: 'Services',
                type : 'basic',
                icon : 'heroicons_outline:document-text',
                link : '/p/services/main'
            }
            ,{
                id   : 'p.services.bookings',
                title: 'Bookings',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-list',
                link : '/p/services/bookings'
            }
        ]
    }

    ,{
        id      : 'p.tools',
        title   : 'Tools',
        subtitle: '',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'p.tools.calendar',
                title: 'Calendar',
                type : 'basic',
                icon : 'heroicons_outline:calendar',
                link : '/p/tools/calendar'
            }
            
        ]
    }

   
    
    //  {
    //     id   : 'p.services.bookings',
    //     title: 'Bookings',
    //     type : 'basic',
    //     icon : 'heroicons_outline:clipboard-list',
    //     link : '/p/services/bookings'
    //  }

    // {
    //     id      : 'apps.ecommerce',
    //     title   : 'ECommerce',
    //     type    : 'collapsable',
    //     icon    : 'heroicons_outline:shopping-cart',
    //     children: [
    //         {
    //             id   : 'apps.ecommerce.inventory',
    //             title: 'Inventory',
    //             type : 'basic',
    //             link : '/apps/ecommerce/inventory'
    //         }
    //     ]
    // }
    
];
export const compactNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'example',
    //     title: 'Example',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/example'
    // }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'example',
    //     title: 'Example',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/example'
    // }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    // {
    //     id   : 'example',
    //     title: 'Example',
    //     type : 'basic',
    //     icon : 'heroicons_outline:chart-pie',
    //     link : '/example'
    // }
];
