import { Route, Routes } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { CocoServicesResolver } from './modules/private/services/services-main/services-main.resolvers';
// import { AuthGuardService } from './services/guards/auth-guard.service';
// import { BudgetHomeComponent } from './modules/public/master-page/budget-home/budget-home.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    /** ORDEN RECOMENDADO EN DECLARACION DE RUTAS
     *   1. Rutas fijas                                         > { path: 'name', ... }
     *   2. Ruta vacia                                          > { path:     '', ... }
     *   3. Cualquier ruta que no pertenece a las anteriores    > { path:   '**', .. }
     */


    /** CONCEPTO DE ESTRUCTURA
     * app-routes   {
     *          private {
     *                      otras-rutas: [...],
     *                      { path:   '**', redirectTo: 'RUTA-POR-DEFAULT' }
     *                  }
     *          public  {
     *                      otras-rutas: [...],
     *                      { path:   '**', redirectTo: 'RUTA-POR-DEFAULT' }
     *                  }
     *              },
     *          default { path:   '**', redirectTo: 'RUTA-POR-DEFAULT' }
     */


    { path: "",redirectTo:"/public/default",pathMatch:"full"},

    // { path: "**",redirectTo:"/public/home"},
    // {
    //     path: '**',
    //     component  : LayoutComponent,
    //     data: {
    //         layout: 'modern'
    //     }
    //     ,children   : [
    //         {path: '', loadChildren: () => import('app/modules/public/master-page/budget-home/budget-home.module').then(m => m.BudgetHomeModule)}
    //     ]
    // },
    

    // Example pages that require Authentication:
    // {
    //     path: '',
    //     canActivate: [AuthGuardService],
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty'
    //     }
    // },

   


    {
        path: 'public',
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        // resolve    : {
        //     initialData: InitialDataResolver,
        // },
        data: {
            layout: 'modern'
        }
        //, pathMatch: 'full'

        ,children   : [
            {path: 'home', loadChildren: () => import('app/modules/public/master-page/budget-home/budget-home.module').then(m => m.BudgetHomeModule)},
            {path: 'default', loadChildren: () => import('app/modules/public/master-page/budget-default/budget-default.module').then(m => m.BudgetDefaultModule)},
            {path: 'details/:uidCard', loadChildren: () => import('app/modules/public/master-page/budget-details/budget-details.module').then(m => m.BudgetDetailsModule)},
            {path: 'details', loadChildren: () => import('app/modules/public/master-page/budget-details/budget-details.module').then(m => m.BudgetDetailsModule)},
            {path: 'contact', loadChildren: () => import('app/modules/public/master-page/customer-contact/customer-contact.module').then(m => m.CustomerContactModule)},
            {path: 'pricing', loadChildren: () => import('app/modules/public/master-page/pricing/pricing.module').then(m => m.PricingModule)},
        ]

        //loadChildren: () => import('app/modules/public/public.module').then(m => m.PublicModule)
    },

    {
        path: '',
        component  : LayoutComponent,
        data: {
            layout: 'modern'
        }

        ,children   : [
             {path: 'akumal', loadChildren: () => import('app/modules/public/master-page/budget-akumal/budget-akumal.module').then(m => m.BudgetAkumalModule)},
             {path: 'agencies', loadChildren: () => import('app/modules/public/master-page/budget-agencies/budget-agencies.module').then(m => m.BudgetAgenciesModule)},
             { path: 'agencies/:codeuser', loadChildren: () => import('app/modules/public/master-page/budget-agencies/budget-agencies.module').then(m => m.BudgetAgenciesModule) },
             {path: 'reserve', loadChildren: () => import('app/modules/public/master-page/budget-reserve/budget-reserve.module').then(m => m.BudgetReserveModule)},
             { path: 'reserve/:codereserve', loadChildren: () => import('app/modules/public/master-page/budget-reserve/budget-reserve.module').then(m => m.BudgetReserveModule) },
             {path: 'privatereserve', loadChildren: () => import('app/modules/public/master-page/budget-privatereserve/budget-privatereserve.module').then(m => m.BudgetPrivatereserveModule)},
             { path: 'privatereserve/:codereserve', loadChildren: () => import('app/modules/public/master-page/budget-privatereserve/budget-privatereserve.module').then(m => m.BudgetPrivatereserveModule) },
            //  {path: 'instructionsread', loadChildren: () => import('app/modules/public/master-page/tracking-instruction-read/tracking-instruction-read.module').then(m => m.TrackingInstructionReadModule)},
             {path: 'quote', loadChildren: () => import('app/modules/public/master-page/budget-quote/budget-quote.module').then(m => m.BudgetQuoteModule)},
             {path: 'quote/price/:codereserve', loadChildren: () => import('app/modules/public/master-page/budget-quote/budget-quote.module').then(m => m.BudgetQuoteModule) },
             {path: 'privatequote', loadChildren: () => import('app/modules/public/master-page/budget-privatequote/budget-privatequote.module').then(m => m.BudgetPrivatequoteModule)},
             {path: 'privatequote/price/:codereserve', loadChildren: () => import('app/modules/public/master-page/budget-privatequote/budget-privatequote.module').then(m => m.BudgetPrivatequoteModule) },
             
             { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
             { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            
             { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
             {path: 'sign-in', loadChildren: () => import('app/modules/public/authentication/sign-in/sign-in.module').then(m => m.SignInModule)},
             {path: 'company', loadChildren: () => import('app/modules/public/authentication/create-enterprise/create-enterprise.module').then(m => m.CreateEnterpriseModule)},
             {path: 'company', loadChildren: () => import('app/modules/public/authentication/activate-enterprise/activate-enterprise.module').then(m => m.ActivateEnterpriseModule)},
             //{path: 'demo-sign-up', loadChildren: () => import('app/modules/public/authentication/demo-sign-up/demo-sign-up.module').then(m => m.DemoSignUpModule)},
            ]
    },

    {
        path: '',
        // canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            
            // {path: 'confirmation-email', loadChildren: () => import('app/modules/auth/confirmation-email/confirmation-email.module').then(m => m.ConfirmationEmailModule)}
        
        ]
    },

    {
        path: '',
        // canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            
            {path: 'confirmation-email', loadChildren: () => import('app/modules/auth/confirmation-email/confirmation-email.module').then(m => m.ConfirmationEmailModule)}
            ,{ path: 'delete-inactive-old-users', loadChildren: () => import('app/modules/public/authentication/delete-inactive-old-users/delete-inactive-old-users.module').then(m => m.DeleteInactiveOldUsersModule) }
        ]
    },

    {
        path: 'p',
        // canActivate: [AuthGuardService],
        // canActivateChild: [AuthGuardService],
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component  : LayoutComponent,
        data: {
            layout: 'classic'
        }
        ,resolve    : {
                    initialData: InitialDataResolver,
                }
        ,children   : [
            {path: 'main', loadChildren: () => import('app/modules/private/main/main.module').then(m => m.MainModule)},
            // {path: 'contact', loadChildren: () => import('app/modules/public/master-page/customer-contact/customer-contact.module').then(m => m.CustomerContactModule)},
            {path: 'services', children   : [
                {
                    path      : '',
                    pathMatch : 'full',
                    redirectTo: 'main'
                }
               ,{ path: 'bookings', loadChildren: () => import('app/modules/private/services/services-bookings/services-bookings.module').then(m => m.ServicesBookingsModule)}
               ,{ path: 'main', loadChildren: () => import('app/modules/private/services/services-main/services-main.module').then(m => m.ServicesMainModule)}
            ]},
            {path: 'tools', children   : [
                {
                    path      : '',
                    pathMatch : 'full',
                    redirectTo: 'calendar'
                }
               ,{ path: 'calendar', loadChildren: () => import('app/modules/private/tools/tools-calendar/tools-calendar.module').then(m => m.ToolsCalendarModule)}
               
            ]},
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
        ]
    },

    {
        path: '**',
        redirectTo: '/public/default'
    }
];
