import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { HttpParams } from '@angular/common/http';
import { BudgetHomeService } from 'app/services/budget-home.service';

@Component({
    selector     : 'modern-layout',
    templateUrl  : './modern.component.html',
    styleUrls: ['./modern.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModernLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    companyTradeName: string = '';
    companyUrlShow: string = '';
    branchAddress: string = '';
    companyEmail: string = '';
    companyPhone: string = '';
    companyCellPhone: string = '';
    bitCompanyDemo: boolean = false;
    branchFB: string = '';
    branchIG: string = '';
    branchLI: string = '';
    branchTA: string = '';
    folderImageIsologo: string = './../../../../../assets/images/isologo/';
    nameImageIsologo: string = '';
    pathImageIsologo: string = '';
    errorImageIsologo: boolean = false;
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
        ,private _budgethomeService: BudgetHomeService
    )
    {
        // let paramsAppend = new HttpParams();
        // paramsAppend = paramsAppend.set("module", "sign-in");
        this._budgethomeService.GetCompanyConfig().subscribe((response) => {
          this.companyTradeName = response.companyconfig.vchCompanyTradeName.toUpperCase();
          this.companyUrlShow = response.companyconfig.vchUrlShow;
        //   this.companyUrlShow = response.companyconfig.vchUrlShow;
        //   this.companyEmail= response.companyconfig.vchEmail;
        //   this.companyPhone = response.companyconfig.vchPhone;
        //   this.companyCellPhone = response.companyconfig.vchCellPhone.replace(/(\s|[&\/\\#,+=()$~%.'":*?<>{}])/g, '');
        this.branchAddress = response.companyconfig.objBranchGeneral.vchAddress;
          this.companyEmail= response.companyconfig.objBranchGeneral.vchEmail;
          this.companyPhone = response.companyconfig.objBranchGeneral.vchPhone;
          try{
            this.companyCellPhone = response.companyconfig.objBranchGeneral.vchWhatsApp.replace(/(\s|[&\/\\#,+=()$~%.'":*?<>{}])/g, '');
          }
          catch{

          }
          
          this.branchFB = response.companyconfig.objBranchGeneral.vchFaceBook;
          this.branchIG = response.companyconfig.objBranchGeneral.vchInstagram;
          this.branchLI = response.companyconfig.objBranchGeneral.vchLinkedIn;
          this.branchTA = response.companyconfig.objBranchGeneral.vchTripAdvisor;

          this.bitCompanyDemo = response.companyconfig.bitDemo;
            try{
                var objImgIsologo = response.companyconfig.listImages.find(c => c.bitLightTheme === true && c.bitIsologo === true);
                this.nameImageIsologo = objImgIsologo.vchName;
                 this.pathImageIsologo = this.folderImageIsologo + this.nameImageIsologo;
            }
            catch{
                
            }

        }, (error) => {
            console.log('error:'+error.message);
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    colorhomebutton():string{
        var href = this._router.url;
        if(href.indexOf('default') >= 0){
            return 'primary'
        }
        else{
            return ''
        }
    }
    colorcontactbutton():string{
        var href = this._router.url;
        if(href.indexOf('contact') >= 0){
            return 'primary'
        }
        else{
            return ''
        }
    }

    colorpricingbutton():string{
        var href = this._router.url;
        if(href.indexOf('pricing') >= 0){
            return 'primary'
        }
        else{
            return ''
        }
    }

    colorsigninbutton():string{
        var href = this._router.url;
        if(href.indexOf('sign-in') >= 0){
            return '#4f46e5'
        }
        else{
            return ''
        }
    }

    colorsigninbuttonlabel():string{
        var href = this._router.url;
        if(href.indexOf('sign-in') >= 0){
            return '#ffffff'
        }
        else{
            return ''
        }
    }

    colorsignupbutton():string{
        var href = this._router.url;
        if(href.indexOf('sign-up') >= 0){
            return '#4f46e5'
        }
        else{
            return ''
        }
    }

    colorsignupbuttonlabel():string{
        var href = this._router.url;
        if(href.indexOf('sign-up') >= 0){
            return '#ffffff'
        }
        else{
            return ''
        }
    }
}
