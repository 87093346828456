import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IUserInfo } from 'app/core/models/account/user-info';
import { Observable, of } from 'rxjs';

@Injectable()
export class AccountService {
    private apiURL = this.baseUrl + "api/account"

    constructor(
        private http:HttpClient,
        @Inject('BASE_URL') private baseUrl: string) { }

        create(userInfo: IUserInfo): Observable<any> {
            return this.http.post<any>(this.apiURL + "/Create",userInfo);
        }

        login(userInfo: IUserInfo): Observable<any> {
            return this.http.post<any>(this.apiURL + "/Login", userInfo);
        }

        getToken(): string {
            return localStorage.getItem("token");
        }

        getTokenExpiration(): string{
            return localStorage.getItem("tokenExpiration");
        }

        logout() {
            console.log('logout');
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
        }

        isLoggedIn(): boolean
        {
            var exp = this.getTokenExpiration();

            if(!exp) {
                // Token doesn't exist
                return false;
            }

            var now = new Date().getTime();
            var expirationDate = new Date(exp);

            if(now >= expirationDate.getTime()) {
                // Ya expiró el token
                // console.log('expiro');
                localStorage.removeItem("token");
              localStorage.removeItem("tokenExpiration");
              return false;
            }else{
                return true;
            }
        }
}
