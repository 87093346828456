import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpSentEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountService } from './account.service'

@Injectable()
export class AuthInterceptorService implements HttpInterceptor{

  constructor(private accountService: AccountService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
      var token = this.accountService.getToken();
      req = req.clone({
        setHeaders : { Authorization: "bearer " + token }
        });

      return next.handle(req);
  }
}
