<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="flex items-center h-20 p-6 pb-0">
            <img
                class="dark:hidden w-60"
                [src]="folderImageIsologo + nameImageIsologo" (error)="errorImageIsologo = true"
                alt="Logo image">
            <img
                class="hidden dark:flex w-60"
                [src]="folderImageIsologo + nameImageIsologo" (error)="errorImageIsologo = true"
                alt="Logo image">
        </div>
        <!-- <div class="flex flex-col items-center justify-center w-full mt-6">
                <div class="w-full whitespace-nowrap text-ellipsis overflow-hidden text-center leading-normal font-medium">
                    {{user.name}} 
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary">
                    {{user.email}}
                </div>
         </div> -->
    </ng-container>
</fuse-vertical-navigation>
<!-- <fuse-vertical-navigation
    [appearance]="'classic'"
    [autoCollapse]="true"
    [inner]="false"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'leftNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall"
    [position]="'left'"
    [transparentOverlay]="false">
</fuse-vertical-navigation> -->

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>
            <notifications></notifications>
            <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button>
            <!-- <user></user> -->
            <button
                  mat-flat-button
                   routerLink="/p/sign-out">
                  <mat-icon>power_settings_new</mat-icon>
            </button>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
            
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div> -->

</div>

<!-- Quick chat -->
<quick-chat #quickChat="quickChat"></quick-chat>
