import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from 'app/core/user/user.service';
import { listedplace } from 'app/types/listedplace.type';
import { BehaviorSubject, Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BudgetHomeService  {
  private apiURL = this.baseUrl + 'api/customer';
  private jwtHelper = new JwtHelperService();
  private _authenticated: boolean = false;
  isLoading$ = new BehaviorSubject<boolean>(false);
  private _listedplace: ReplaySubject<listedplace> = new ReplaySubject<listedplace>(1);

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private _usersService: UserService,
  ) { }

  NewReserveAirportTransfer(model: any): Promise<any> {
    this.isLoading$.next(true);
    // return new Promise((resolve, reject) => {
    //     this.http.post(this.apiURL + '/newcontact', model)
    //         .subscribe((response: any) => 
    //             resolve(response));
                
            
    //         // ,
    //         //     (error) => {
    //         //         this.isLoading$.next(false);
    //         //         reject(error);
    //         //     },
    //         //     () => {
    //         //         this.isLoading$.next(false);
    //         //     }
    //             // );
    // });

    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/newreserveairporttransfer', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
      
  }

//   GetCompanyConfig1(): Promise<any> {
//     return this.http.get<any>('api/company' + '/getCompanyConfig' )
//     .toPromise()
//     .then(
//       data => {
//         this.dictionaries.set("DISCARD_REASONS",data);
//       }
//     )
//   }

  NewReserveAirportTransferagencies(model: any): Promise<any> {
    this.isLoading$.next(true);
    
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/newreserveairporttransferagencies', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
      
  }

  NewReserveAirportTransferakumal(model: any): Promise<any> {
    this.isLoading$.next(true);
    
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/newreserveairporttransferakumal', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
      
  }

  NewReservePrivateTransportation(model: any): Promise<any> {
    this.isLoading$.next(true);
    // return new Promise((resolve, reject) => {
    //     this.http.post(this.apiURL + '/newcontact', model)
    //         .subscribe((response: any) => 
    //             resolve(response));
                
            
    //         // ,
    //         //     (error) => {
    //         //         this.isLoading$.next(false);
    //         //         reject(error);
    //         //     },
    //         //     () => {
    //         //         this.isLoading$.next(false);
    //         //     }
    //             // );
    // });

    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/newreserveprivatetransportation', model)
          .subscribe((response: any) => {
              // console.log(response);
              resolve(response);
              
          },
              (error) => {

                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
      
  }

  NewReservePrivateTransportationAgencies(model: any): Promise<any> {
    this.isLoading$.next(true);
   
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/newreserveprivatetransportationagencies', model)
          .subscribe((response: any) => {
              // console.log(response);
              resolve(response);
              
          },
              (error) => {

                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
      
  }

  TransformFilePrivateTransportation(model: any): Observable<any> {
    this.isLoading$.next(true);
    let headers = new HttpHeaders();
    headers.set('content-type', 'application/x-www-form-urlencoded');
    return  this.http.get<any>(this.apiURL + '/transformfileprivatetransportation', {params:model}
    // ,
    // {headers: headers}
    );
        // .subscribe((response: any) => {
        //     // console.log(response);
        //     resolve(response);
            
        // },
        //     (error) => {

        //         this.isLoading$.next(false);
        //         reject(error);
        //     },
        //     () => {
        //         this.isLoading$.next(false);
        //     });
      
  }

  GetBookingPrice(model: any): Observable<any> {
    this.isLoading$.next(true);
    let headers = new HttpHeaders();
    headers.set('content-type', 'application/x-www-form-urlencoded');
    return  this.http.get<any>(this.apiURL + '/getbookingprice', {params:model}
    
    );
  }

  GetBookingPrivatePrice(model: any): Observable<any> {
    this.isLoading$.next(true);
    let headers = new HttpHeaders();
    headers.set('content-type', 'application/x-www-form-urlencoded');
    return  this.http.get<any>(this.apiURL + '/getbookingprivateprice', {params:model}
    
    );
  }

  GetNumberReserve(model: any): Observable<any> {
    this.isLoading$.next(true);
    let headers = new HttpHeaders();
    headers.set('content-type', 'application/x-www-form-urlencoded');
    return  this.http.get<any>(this.apiURL + '/getnumberreserve', {params:model} );
  }

  NewModifyReserveAirport(model: any): Promise<any> {
    this.isLoading$.next(true);

    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/addupdatereserveairport', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
      
  }

  NewModifyReserveGeneral(model: any): Promise<any> {
    this.isLoading$.next(true);

    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/addupdatereservegeneral', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
      
  }

  NewModifyReserveArrival(model: any): Promise<any> {
    this.isLoading$.next(true);

    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/addupdatereservearrival', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
      
  }

  NewModifyReserveDeparture(model: any): Promise<any> {
    this.isLoading$.next(true);

    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/addupdatereservedeparture', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
      
  }

  NewModifyALLReserve(model: any): Promise<any> {
    this.isLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/addupdateentirereserveairport', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
  }

  ModifyALLPrivateReserve(model: any): Promise<any> {
    this.isLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/updateentirereserveprivate', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
  }

  ConfirmBookingCustomer(model: any): Promise<any> {
    this.isLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/confirmbookingcustomer', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
  }

  ConfirmBookingPrivateCustomer(model: any): Promise<any> {
    this.isLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/confirmbookingprivatecustomer', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
  }

  ConfirmBookingAdmin(model: any): Promise<any> {
    this.isLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/saveconfirmbookingadmin', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
  }

  ConfirmBookingPrivateAdmin(model: any): Promise<any> {
    this.isLoading$.next(true);
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/saveconfirmbookingprivateadmin', model)
          .subscribe((response: any) => {
              resolve(response);
          },
              (error) => {
                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
  }


  GetAirportReserve(model: any): Observable<any> {
    this.isLoading$.next(true);
    let headers = new HttpHeaders();
    headers.set('content-type', 'application/x-www-form-urlencoded');
    return  this.http.get<any>(this.apiURL + '/getreserveairport', {params:model} );
  }

  GetPrivateReserve(model: any): Observable<any> {
    this.isLoading$.next(true);
    let headers = new HttpHeaders();
    headers.set('content-type', 'application/x-www-form-urlencoded');
    return  this.http.get<any>(this.apiURL + '/getreserveprivate', {params:model} );
  }

  GetLinkCalendarArrival(model: any):Promise<any>{
    this.isLoading$.next(true);
    let headers = new HttpHeaders();
    headers.set('content-type', 'application/x-www-form-urlencoded');
   return new Promise((resolve, reject) => {
        this.http.post(this.apiURL + '/getlinkcalendararrivalreserve', model)
            .subscribe((response: any) => {
                resolve(response);
            },
                (error) => {
                    this.isLoading$.next(false);
                    reject(error);
                },
                () => {
                    this.isLoading$.next(false);
                });
      });
  }

  GetLinkCalendarDeparture(model: any):Promise<any>{
    this.isLoading$.next(true);
    let headers = new HttpHeaders();
    headers.set('content-type', 'application/x-www-form-urlencoded');
   return new Promise((resolve, reject) => {
        this.http.post(this.apiURL + '/getlinkcalendardeparturereserve', model)
            .subscribe((response: any) => {
                resolve(response);
            },
                (error) => {
                    this.isLoading$.next(false);
                    reject(error);
                },
                () => {
                    this.isLoading$.next(false);
                });
      });
  }

  GetLinkCalendarDetails(model: any):Promise<any>{
    this.isLoading$.next(true);
    let headers = new HttpHeaders();
    headers.set('content-type', 'application/x-www-form-urlencoded');
   return new Promise((resolve, reject) => {
        this.http.post(this.apiURL + '/getlinkcalendardetailsreserve', model)
            .subscribe((response: any) => {
                resolve(response);
            },
                (error) => {
                    this.isLoading$.next(false);
                    reject(error);
                },
                () => {
                    this.isLoading$.next(false);
                });
      });
  }

  UserCodeRecoveryByEmail(model: any): Promise<any> {
    this.isLoading$.next(true);
   
    return new Promise((resolve, reject) => {
      this.http.post(this.apiURL + '/usercoderecoverybyemail', model)
          .subscribe((response: any) => {
              // console.log(response);
              resolve(response);
              
          },
              (error) => {

                  this.isLoading$.next(false);
                  reject(error);
              },
              () => {
                  this.isLoading$.next(false);
              });
    });
        
  }

  

   adaptResultToBase64(res: Blob): Promise<string> {
    let reader: FileReader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onloadend = () => {
            resolve(reader.result as string);
        }
        reader.onerror = () => {
            reject("Error reading file.");
        }
        reader.readAsDataURL(res);
    })
  }

  GetRangeDateTimeByModule(module: any): Observable<any> {
    // this.isLoading$.next(true);
     return this.http.get<any>(this.apiURL + '/getRangeDateTimeByModule', {params:module});
  }

  GetRangeDateTimeByCodeUser(module: any): Observable<any> {
    // this.isLoading$.next(true);
     return this.http.get<any>(this.apiURL + '/getRangeDateTimeByCodeUser', {params:module});
  }
  
  getlistedplaces(): Observable<any>
  {
    //this.isLoading$.next(true);

      // return this.http.get<any>(this.apiURL + '/getlistedplaces').pipe(
      //   tap((response) => {
      //     this._listedplace.next(response);
      //   })
      // );
      return this.http.get<any>(this.apiURL + '/getlistedplaces');
      // return this.http.get(this.apiURL + '/getlistedplaces')
      //          .toPromise()
      //          .then(response => response.json().data as listedplace[])
      //          .catch(this.handleError);
  }
  getlistedprivateplaces(): Observable<any>
  {
      return this.http.get<any>(this.apiURL + '/getlistedprivateplaces');
  }
  GetAirportPlacesAssignedByModule(module: any): Observable<any> {
    // this.isLoading$.next(true);
     return this.http.get<any>(this.apiURL + '/getAirportLocationsAssignedByModule', {params:module});
  }
  getlistedplacesprivateorigin(): Observable<any>
  {
      return this.http.get<any>(this.apiURL + '/getlistedplacesprivateorigin');
  }
  getlistedairlines(): Observable<any>
  {
      return this.http.get<any>(this.apiURL + '/getlistedairlines');
  }
  getlistedoperators(): Observable<any>
  {
      return this.http.get<any>(this.apiURL + '/getlistedoperators');
  }
  getlisteddrivers(): Observable<any>
  {
      return this.http.get<any>(this.apiURL + '/getlisteddrivers');
  }
  getlistedvehicles(): Observable<any>
  {
      return this.http.get<any>(this.apiURL + '/getlistedvehicles');
  }
  getlistedstatusbyreserve(): Observable<any>
  {
      return this.http.get<any>(this.apiURL + '/getlistedstatusbyreserve');
  }
  getlistedterminals(): Observable<any>
  {
      return this.http.get<any>(this.apiURL + '/getlistedterminals');
  }
  getlistedcurrenciesbyreserve(): Observable<any>
  {
      return this.http.get<any>(this.apiURL + '/getlistedcurrenciesbyreserve');
  }



  getconfrangedatestimes(): Observable<any>
  {
      return this.http.get<any>(this.apiURL + '/getconfrangedatestimes');
  }
  
  getcustomeragencies(codeuser: any): Observable<any> {
   // this.isLoading$.next(true);
    return this.http.get<any>(this.apiURL + '/getcustomeragencies', {params:codeuser});
  }
  getmodulerequirementcodes(module: any): Observable<any> {
    // this.isLoading$.next(true);
     return this.http.get<any>('api/account' + '/getmodulerequirementcodes', {params:module});
   }

   getlevelaccessreserve(model: any): Observable<any> {
    // this.isLoading$.next(true);
     return this.http.get<any>(this.apiURL + '/getlevelaccessreserve', {params:model});
   }

   getTravelTimeAirportTransfer(model: any): Observable<any> {
    // this.isLoading$.next(true);
     return this.http.get<any>(this.apiURL + '/gettraveltimeairporttransfer', {params:model});
   }

   getTravelTimePrivateTransportation(model: any): Observable<any> {
    // this.isLoading$.next(true);
     return this.http.get<any>(this.apiURL + '/gettraveltimeprivatetransportation', {params:model});
   }

   InstructionsRead(model: any):Observable<any>{
    return this.http.get<any>(this.apiURL + '/setInstructionRead', {params:model});
   }

   GetConfBookingsFields(): Observable<any>
  {
      return this.http.get<any>(this.apiURL + '/getConfBookingsFields');
  }
  GetCompanyConfig(): Observable<any> {
    this.isLoading$.next(true);
    let headers = new HttpHeaders();
    headers.set('content-type', 'application/x-www-form-urlencoded');
    return  this.http.get<any>('api/company' + '/getCompanyConfig' );
  }

  

  GetModuleConfig(model: any): Observable<any> {
    this.isLoading$.next(true);
    let headers = new HttpHeaders();
    headers.set('content-type', 'application/x-www-form-urlencoded');
    return  this.http.get<any>('api/company' + '/getModuleConfig', {params:model} );
  }

}
