<fuse-splash-screen class="light">
    <!-- <div class="  grid grid-cols-8 "  >
        <div class="col-span-8 sm:col-span-6"  > -->

            <!-- <img style="width: 50%;"
            *ngIf="errorImageIsologo === false"
            [src]="pathImageIsologo"
            (error)="errorImageIsologo = true"
            alt="Fuse logo"> -->

            <!-- DEMO -->
            <img style="width: 50%;"
            *ngIf="errorImageIsologo === false"
            src="./../assets/images/isologo/reservatrix-negro-y-naranja-con-fondo-Transparente.png"
            (error)="errorImageIsologo = true"
            alt="Fuse logo">

            <!-- <img style="width: 50%;"
            *ngIf="errorImageIsologo === false"
            src="./../assets/images/isologo/CocoTravelExpressAZULingles.png"
            (error)="errorImageIsologo = true"
            alt="Fuse logo"> -->

        <!-- </div>
    </div> -->
    <fuse-alert
        class="mt-8 -mb-4"
        *ngIf="showAlert"
        [appearance]="'outline'"
        [showIcon]="false"
        [type]="alert.type"
        [@shake]="alert.type === 'error'">
        {{alert.message}}
    </fuse-alert>

    <div  class="spinner">
        <div class="bounce1" ></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
</fuse-splash-screen>

<div *ngIf="bitActive === false" class="flex flex-col flex-auto w-full min-w-0 "
style="display: flex; flex-direction: row; align-items: center; justify-content: center;"
>
   <div class="  grid grid-cols-8 "  >
    <div class="col-span-1 sm:col-span-1"  ></div>
      <div class="col-span-6 sm:col-span-6" style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
            <img 
            style="width: 50%;"
            *ngIf="errorImageIsologo === false"
            src="./../assets/images/isologo/reservatrix-negro-y-naranja-con-fondo-Transparente.png"
            (error)="errorImageIsologo = true"
            alt="Fuse logo">

            <!-- <img 
            style="width: 50%;"
            *ngIf="errorImageIsologo === false"
            src="./../assets/images/isologo/CocoTravelExpressAZULingles.png"
            (error)="errorImageIsologo = true"
            alt="Fuse logo"> -->
      </div>
      <div class="col-span-1 sm:col-span-1"  ></div>
      <div class="col-span-1 sm:col-span-1"  ></div>
      <div class="col-span-6 sm:col-span-6" style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
           
            
            <fuse-alert
            class="mt-8 -mb-4"
            *ngIf="showAlert"
            [appearance]="'outline'"
            [showIcon]="false"
            [type]="alert.type"
            [@shake]="alert.type === 'error'">
            {{alert.message}}
                </fuse-alert>
      </div>
      <div class="col-span-1 sm:col-span-1"  ></div>
   </div>
</div>


<router-outlet  *ngIf="bitActive === true" ></router-outlet>
