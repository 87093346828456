import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, map, Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { IUserInfo } from '../models/account/user-info';
import { Module } from '../user/user.types';
import { FuseNavigationItem } from '@fuse/components/navigation';

@Injectable()
export class AuthService
{
    private apiURL = this.baseUrl + 'api/account';
    private _authenticated: boolean = false;
    http: any;
    isLoading$ = new BehaviorSubject<boolean>(false);
    /**
     * Constructor 
     */
    public uiduser: string='';
    public emailuser: string='';
    public vchusername: string='';
    constructor(
        private _httpClient: HttpClient,
        @Inject('BASE_URL') private baseUrl: string,
        private _userService: UserService
    )
    {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    handleError(error: Response): Observable<any> {
        return throwError(error);
    }

    forgotPassword(queryParams: any): Observable<any>
    {
        // return this._httpClient.post('api/auth/forgot-password', email);
        return this._httpClient
            .get(`${this.apiURL}/forgotpassword?email=${encodeURIComponent(queryParams)}`)
            .pipe(
                map((result: any) => {
                    // Store result on local storage
                    if (result.tokenBaseViewModel) {
                        localStorage.setItem('tokenRecovery', result.tokenBaseViewModel.token);
                        localStorage.setItem('tokenExpirationRecovery', result.tokenBaseViewModel.expiration);
                    }
            
                    if (result.email) {
                        localStorage.setItem('emailRecovery', result.email);
                    }
                    return result;
                }),
                catchError(this.handleError)
            );
    }

    /**
     * Reset password
     *
     * @param password
     */
    // resetPassword(password: string): Observable<any>
    // {
    //     return this._httpClient.post('api/auth/reset-password', password);
    // }
    resetPassword(model: any): Promise<any> {
        this.isLoading$.next(true);
        return new Promise((resolve, reject) => {
          this._httpClient.post(this.apiURL + '/resetpassword', model)
              .subscribe((response: any) => {
                  resolve(response);
              },
                  (error) => {
    
                      this.isLoading$.next(false);
                      reject(error);
                  },
                  () => {
                      this.isLoading$.next(false);
                  });
        });
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            
            return throwError('User is already logged in.');
            
        }
            
        return this._httpClient.post(this.baseUrl + "api/account/Login", credentials).pipe(
            switchMap((response: any) => {
                
                // Store the access token in the local storage
                this.accessToken = response.token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.userInfo;
                this._userService.modules = response.modulesAccess;
                this._userService.elementsVNB = response.elementsVNB;
                this.uiduser = response.userInfo.uid;
                this.emailuser = response.userInfo.email;
                this.vchusername = response.userInfo.name + ' ' + response.userInfo.lastname;
                
                // this._userService.elementsVNB$.subscribe(list => {
                //     var modules = list as FuseNavigationItem[];
                //     console.log(modules);
                // });
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Renew token
        // return this._httpClient.post('api/auth/refresh-access-token'
        return this._httpClient.post('api/account/GetRefreshToken', {
            
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>
             
                // Return false
                of(false)
            ),
            switchMap((response: any) => {
                
                // Store the access token in the local storage
                this.accessToken = response.token;

                // Set the authenticated flag to true
                this._authenticated = true;

                console.log(response.userInfo);
                // Store the user on the user service
                this._userService.user = response.userInfo;
                this._userService.modules = response.modulesAccess;
                this._userService.elementsVNB = response.elementsVNB;
                this.uiduser = response.userInfo.uid;
                this.emailuser = response.userInfo.email;
                this.vchusername = response.userInfo.name + ' ' + response.userInfo.lastname;
                // console.log(response.userInfo);
                // this._userService.elementsVNB$.subscribe(list => {
                //     var modules = list as FuseNavigationItem[];
                //     console.log(modules);
                // });
                // Return true
                return of(true);
            })
        );

        // return this.http.get(this.apiURL + '/GetRefreshToken').pipe(
        //     catchError(() =>

        //         // Return false
        //         of(false)
        //     ),
        //     switchMap((response: any) => {
        //        
        //         // Store the access token in the local storage
        //         this.accessToken = response.accessToken;

        //         // Set the authenticated flag to true
        //         this._authenticated = true;

        //         // Store the user on the user service
        //         this._userService.user = response.user;

        //         // Return true
        //         return of(true);
        //     })
        // );
    }

    // signInUsingToken(): Observable<any> {
    //     // Renew token

    //      var credentials: { accessToken: string;  }={
    //         accessToken: this.accessToken
    //     }
    //     return this._httpClient.post(this.baseUrl + 'api/account/GetRefreshToken', credentials).pipe(
    //         catchError(() =>

    //             // Return false
    //             of(false)
    //         ),
    //         switchMap((response: any) => {
                
    //             // Store the access token in the local storage
    //             this.accessToken = response.token;

    //             // Set the authenticated flag to true
    //             this._authenticated = true;

    //             // Store the user on the user service
    //             this._userService.user = response.userInfo;
    //             this._userService.modules = response.modulesAccess;
    //             this._userService.elementsVNB = response.elementsVNB;
    //             this.uiduser = response.userInfo.uid;
    //             this.vchusername = response.userInfo.name + ' ' + response.userInfo.lastname;
    //             console.log(response.userInfo);
    //             // this._userService.elementsVNB$.subscribe(list => {
    //             //     var modules = list as FuseNavigationItem[];
    //             //     console.log(modules);
    //             // });
    //             // Return true
    //             return of(true);
    //         })
    //     );
    // }
    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
       
        localStorage.removeItem('accessToken');

        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration"); 

        // Set the authenticated flag to false
        this._authenticated = false;

        
        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { username: string; name: string; middlename: string; lastname: string; email: string; password: string; company: string; promotioncode: string; requiredcode: string; }): Observable<any>
    {
        // return this._httpClient.post('api/account/create', user);
        return this._httpClient
            .post<any>(this.apiURL + '/create', user)
            .pipe(
                map((result: any) => {
                    // Store result on local storage
                    this.setLocalStorage(result);
                    return result;
                }),
                catchError(this.handleError)
            );
    }

    confirmUser(userid: string, confirm: string): Observable<any> {
        return this._httpClient
            .get(`${this.apiURL}/confirmemail?userid=${encodeURIComponent(userid)}&confirm=${encodeURIComponent(confirm)}`);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    setLocalStorage(result: any): void {

        if (result.tokenBaseViewModel) {
            localStorage.setItem('token', result.tokenBaseViewModel.token);
            localStorage.setItem('tokenExpiration', result.tokenBaseViewModel.expiration);
        }

        if (result.email) {
            localStorage.setItem('email', result.email);
        }

        // localStorage.setItem('user', result.usersSimpleViewModel);
    }

    getPrivateModule(model: any): Promise<any> {
        this.isLoading$.next(true);
       
        return new Promise((resolve, reject) => {
          this._httpClient.post(this.apiURL + '/getprivatemodule', model)
              .subscribe((response: any) => {
                 
                  resolve(response);
                  
              },
                  (error) => {
    
                      this.isLoading$.next(false);
                      reject(error);
                  },
                  () => {
                      this.isLoading$.next(false);
                  });
        });
            
      }

    validateAccessToModule(module: Module): boolean 
    {
        var validate = false;
        this.isLoading$.next(true);

        var index = -1;
       
        this._userService.modules$.subscribe(list => {
            var modules = list as Module[];
            index = modules.findIndex(obj => obj.uid === module.uid);
        });
        
        if(index > -1){
            validate = true;
        }
          
        return validate;
    }

    GetSimpleUsersByEmail(module: any): Promise<any> {
        // this.isLoading$.next(true);
        //  return this.http.get<any>(this.apiURL + '/getSimpleUsersByCode', {params:module});
         return new Promise((resolve, reject) => {
            this._httpClient.get(this.apiURL + '/getSimpleUsersByEmail', {params:module})
                .subscribe((response: any) => {
                   
                    resolve(response);
                    
                },
                    (error) => {
      
                        this.isLoading$.next(false);
                        reject(error);
                    },
                    () => {
                        this.isLoading$.next(false);
                    });
          });
      }

      getListedUsersAgentsModuleBookings(): Observable<any>
      {
          return this._httpClient.get<any>(this.apiURL + '/getListedUsersAgentsModuleBookings');
      }
      SetUserAgentBookings(model: any): Observable<any> {
        this.isLoading$.next(true);
        let headers = new HttpHeaders();
        headers.set('content-type', 'application/x-www-form-urlencoded');
        return  this._httpClient.get<any>(this.apiURL + '/setUserAgentBookings', {params:model} );
    }

    VerifyNewCompanyEmail(model: any): Promise<any> {
        this.isLoading$.next(true);
        return new Promise((resolve, reject) => {
          this._httpClient.post(this.apiURL + '/verifyNewCompanyEmail', model)
              .subscribe((response: any) => {
                  resolve(response);
              },
                  (error) => {
    
                      this.isLoading$.next(false);
                      reject(error);
                  },
                  () => {
                      this.isLoading$.next(false);
                  });
        });
    }

    CreateNewUserAdminNewCompany(model: any): Promise<any> {
        this.isLoading$.next(true);
        return new Promise((resolve, reject) => {
          this._httpClient.post(this.apiURL + '/createNewUserAdminNewCompany', model)
              .subscribe((response: any) => {
                  resolve(response);
              },
                  (error) => {
    
                      this.isLoading$.next(false);
                      reject(error);
                  },
                  () => {
                      this.isLoading$.next(false);
                  });
        });
    }
    ReplaceGuidToNewId(model: any): Promise<any> {
        this.isLoading$.next(true);
        return new Promise((resolve, reject) => {
          this._httpClient.post(this.apiURL + '/replaceGuidToNewId', model)
              .subscribe((response: any) => {
                  resolve(response);
              },
                  (error) => {
    
                      this.isLoading$.next(false);
                      reject(error);
                  },
                  () => {
                      this.isLoading$.next(false);
                  });
        });
    }

    DeleteInactiveOldUsers(): Observable<any> {
        this.isLoading$.next(true);
        let headers = new HttpHeaders();
        headers.set('content-type', 'application/x-www-form-urlencoded');
        return  this._httpClient.get<any>(this.apiURL + '/deleteInactiveOldUsers');
    }

    ResubmitUserActivationRequest(model: any): Promise<any> {
        this.isLoading$.next(true);
        return new Promise((resolve, reject) => {
          this._httpClient.post(this.apiURL + '/resubmitUserActivationRequest', model)
              .subscribe((response: any) => {
                  resolve(response);
              },
                  (error) => {
    
                      this.isLoading$.next(false);
                      reject(error);
                  },
                  () => {
                      this.isLoading$.next(false);
                  });
        });
    }
}
