import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AccountService } from '../account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private accountService: AccountService,
    private router: Router
    ) { }

  canActivate(route: ActivatedRouteSnapshot, state:RouterStateSnapshot): boolean{
      if(this.accountService.isLoggedIn()) {
          return true;
      }else{
          this.router.navigate(['/sign-in']);
          return false;
      }
  }
}
